import React from "react"
import VideoCVHeroSection from "./videoCVHeroSection/VideoCVHeroSection"
import VideoCVInfoSection from "./videoCVInfoSection/VideoCVInfoSection"
import VideoCVAdvantagesSection from "./videoCVAdvantagesSection/VideoCVAdvantagesSection"
import VideoCVSliderSection from "./videoCVSliderSection/VideoCVSliderSection"

const VideoCvTemplate = ({
  videoCVHeroSectionImage,
  videCVInfoImageSection,
}) => {
  return (
    <>
      <VideoCVHeroSection videoCVHeroSectionImage={videoCVHeroSectionImage} />
      <VideoCVInfoSection videCVInfoImageSection={videCVInfoImageSection} />
      <VideoCVAdvantagesSection />
      <VideoCVSliderSection />
    </>
  )
}

export default VideoCvTemplate
