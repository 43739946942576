import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import VideoCvTemplate from "../modules/services/videoCV/VideoCv.template"
import { graphql } from "gatsby"

const Videonabludenie = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={"Видеонаблюдение"}
        keywords={
          "СОТ Ямбол, охрана Ямбол, СОД ямбол, Видеонаблюдение,охрана на обекти Ямбол, физическа охрана Ямбол, цени на СоТ Ямбол"
        }
      />
      <VideoCvTemplate
        videoCVHeroSectionImage={data.videoCVHeroSectionImage.edges}
        videCVInfoImageSection={data.videCVInfoImageSection.edges}
      />
    </Layout>
  )
}

export default Videonabludenie

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    videoCVHeroSectionImage: allFile(
      filter: { relativeDirectory: { eq: "videoCVHeroSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    videCVInfoImageSection: allFile(
      filter: { relativeDirectory: { eq: "videCVInfoImageSection" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
