import React from "react"
import VideoCVInfoSectionStyle from "./VideoCVInfoSectionStyle.module.styl"
import Img from "gatsby-image"
import CCTVImage from "./../../../../assets/images/cctv-min.png"
import VideoMonitoringImage from "./../../../../assets/images/monitoring-min.png"

const VideoCVInfoSection = ({ videCVInfoImageSection }) => {
  return (
    <section className={VideoCVInfoSectionStyle.info_section}>
      <div className={VideoCVInfoSectionStyle.intro_section_content}>
        <Img
          className={VideoCVInfoSectionStyle.intro_img}
          fluid={videCVInfoImageSection[0].node.childImageSharp.fluid}
        />
        <div className={VideoCVInfoSectionStyle.intro_text}>
          Видеонаблюдението и видеомониторинга са услуги, които набират все по
          голяма популярност сред нашите клиенти. „Спешъл Сикюрити Груп“ ООД се
          е специализирала в изграждането на такива системи, като предлагаме
          проектиране, изпълнение и въвеждане в експлоатация. Консултацията по
          изграждането на обекти е безплатна, като се дават различни решения в
          комбинация с други наши услуги.
        </div>
      </div>
      <div className={VideoCVInfoSectionStyle.intro_section_content_reversed}>
        <div className={VideoCVInfoSectionStyle.intro_text}>
          <span>Видеонаблюдението</span> е вариант, който може да ви осигури
          спокойствие, като допълнително увеличи нивото ви на сигурност. Тази
          услуга предлага монтирането на камери, които остават за наблюдение от
          самия клиент и се разчита на сигнал подаден към нас от СОТ система.
        </div>
        <img
          className={VideoCVInfoSectionStyle.intro_img}
          src={CCTVImage}
          alt={"cctv"}
        />
      </div>{" "}
      <div className={VideoCVInfoSectionStyle.intro_section_content}>
        <img
          className={VideoCVInfoSectionStyle.intro_img}
          src={VideoMonitoringImage}
          alt={"video-monitoring"}
        />
        <div className={VideoCVInfoSectionStyle.intro_text}>
          <span>Видеомониторинга</span> е най-съвременният вариант на охрана.
          Тази услуга осигурява по-кратко време за реакция, тъй като изградената
          система за видеонаблюдение се обслужва от диспечерите в нашият
          Денонощен дежурен център. Сигналът се подава на моторизираните
          патрули, като се прецизира с точност мястото на нарушение.
        </div>
      </div>
    </section>
  )
}

export default VideoCVInfoSection
