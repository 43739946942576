import React from "react"
import VideoCVAdvantagesSectionStyle from "./VideoCVAdvantagesSectionStyle.module.styl"
import Advantage from "../../../../common/packages/advantage/Advantage"

const VideoCVAdvantagesSection = props => {
  return (
    <section className={VideoCVAdvantagesSectionStyle.sod_advantages}>
      <div className={VideoCVAdvantagesSectionStyle.sod_advantages_content}>
        <h2>Защо да изберете тази услуга ?</h2>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage
              number={1}
              advantage={
                "Подходяща услуга за всякакъв вид обекти - частни и фирмени"
              }
            />{" "}
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage number={2} advantage={"Охрана на движимо имущество"} />{" "}
          </div>{" "}
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage
              number={3}
              advantage={"100% бърза и адекватна реакция"}
            />{" "}
          </div>{" "}
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage
              number={4}
              advantage={"24-часово, непрекъснато наблюдение на вашият обект"}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default VideoCVAdvantagesSection
