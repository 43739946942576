import React from "react"
import VideoCVHeroSectionStyle from "./VideoCVHeroSectionStyle.module.styl"
import Img from "gatsby-image"

const VideoCVHeroSection = ({ videoCVHeroSectionImage }) => {
  return (
    <section className={VideoCVHeroSectionStyle.hero_section}>
      <Img
        className={VideoCVHeroSectionStyle.image}
        fluid={videoCVHeroSectionImage[0].node.childImageSharp.fluid}
      />
      <div className={VideoCVHeroSectionStyle.title_wrapper}>
        <h1>Видеонаблюдение</h1>
      </div>
    </section>
  )
}

export default VideoCVHeroSection
